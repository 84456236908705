import React from 'react';
import BannerAbout from 'components/banners/BannerAbout';
import Container from '@mui/material/Container';
import TitleRow from 'components/cards/TitleRow';
import ContactDetailsPlain from 'components/banners/ContactDetailsPlain';
import AboutDescription from 'components/banners/AboutDescription';

class About extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container maxWidth="xl">
          <BannerAbout />

          <TitleRow
            data={{
              title: 'אודות',
            }}
          />

          <AboutDescription />
          <ContactDetailsPlain />
        </Container>
      </React.Fragment>
    );
  }
}

export default About;
