import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';

export default function AboutDescription() {
  const theme = useTheme();
  // https://mui.com/components/use-media-query/
  // use the media query to check the size of the screen
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  // return the proper justify content parameter based on the screen
  // if larger than sm then use space-between else use center

  return (
    <Box sx={{ backgroundColor: '#F1F1F1', paddingTop: 4, paddingBottom: 4 }}>
      {/* To vertically align the items use  */}
      <Grid container columns={2} justifyContent="center" alignItems="center">
        {/* A value given to a breakpoint applies to all the other breakpoints wider than it unless overridden */}
        {/* For example, xs={12} sizes a component to occupy the whole viewport width regardless of its size. */}
        {/* xs={2} means that by default the width of the column is like the whole viewport */}
        {/* If the size of the viewport is more than sm, then the width of the column is 1 of 2 */}
        <Grid item xs={2} md={1}>
          <Box sx={{ paddingRight: isSm ? 2 : 4, backgroundColor: '#C3D1FF00' }}>
            <Typography variant="body1" sx={{ paddingBottom: 2 }}>
              מר' שאולוב דמיטרי הינו בעל תואר שני בהנדסת תעשיה וניהול MBA. יותר מ 15 שנות ניסיון בתחום של מערכות לוגיסטיות מתקדמות כמנהל תכנון ארגוני ושרשרת אספקה.
              <br />
              יותר מ 6 שנות ניסיון כבעלים ויועץ לימודים של מכללה הטכנולוגית FD ויועץ עסקי בתחום תפעול הארגון בניהול אסטרטגי. מתמחה בתכנון, ייעוץ והובלה של פרויקטים לקידום ארגונים
              בתחום התפעול ושרשרת אספקה.
            </Typography>
            <Typography variant="body1" sx={{}}>
              - בחינת תהליך שרשרת האספקה ואפיון מערכות לוגיסטיות קיימות
              <br />
              - תכנון מערך של מרכזים לוגיסטיים
              <br />
              - תכנון ניצול נכון של שטחי אחסון לפי צרכי הלקוח
              <br />
              - תכנון מחסנים ושיטות ניהול מלאי
              <br />
              - תכנון מערך שינוע, ליקוט והפצה
              <br />
              - אפיון תהליכי עבודה
              <br />
              - בחינת פונקציונליות של מחלקות בארגון
              <br />
              - אפיון מערכות מידע ותמיכה בתהליכי ניהול
              <br />
              - הקמת מדדי ביצוע מרכזיים, KPI, שליטה ובקרה
              <br />- אפיון וליווי תקני איכות נדרשים
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} md={1}>
          <Box display="flex" justifyContent="center" sx={{ padding: 2, backgroundColor: '#FFE7C300' }}>
            <img alt="ial logo" src="/images/avatar-dima.png" />
          </Box>
          <Typography variant="body1" sx={{ paddingRight: isSm ? 2 : 15, paddingLeft: isSm ? 2 : 15 }}>
            בעל ניסיון עשיר בתחומים מגוונים בזכות פרויקטים מוצלחים שבוצעו אצל מגוון רחב של לקוחות. מציע פתרונות ישימים, אפקטיביים ומשתלמים כלכלית שעונים לצרכי הארגון. מתחייב
            למקצועיות, איכות, יצירתיות, אמון ושיתוף פעולה עם הלקוח.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
