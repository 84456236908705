import React from 'react';
import Grid from '@mui/material/Grid';
import ServiceCard from 'components/cards/ServiceCard';
import serviceDataCards from 'components/banners/serviceCardsData';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function BannerServices() {
  const theme = useTheme();
  // https://mui.com/components/use-media-query/
  // use the media query to check the size of the screen
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  // return the proper justify content parameter based on the screen
  // if larger than sm then use space-between else use center
  const gridJustifyContent = () => {
    if (isSm) return 'center';
    else return 'space-between';
  };

  const gridSpacing = () => {
    if (isSm) return 1;
    else return 3;
  };

  return (
    <Grid container columns={3} spacing={gridSpacing()} alignItems="stretch" justifyContent={gridJustifyContent()}>
      <Grid item xs={3} md={1}>
        <ServiceCard data={serviceDataCards.specialization} />
      </Grid>
      <Grid item xs={3} md={1}>
        <ServiceCard data={serviceDataCards.consulting} />
      </Grid>
      <Grid item xs={3} md={1}>
        <ServiceCard data={serviceDataCards.process} />
      </Grid>
    </Grid>
  );
}
