import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function ServiceCard(props) {
  const data = props.data;

  return (
    <Box
      sx={{
        margin: 0,
        backgroundColor: '#F1F1F1',
        height: '100%',
      }}
    >
      <CardMedia
        image={data.image}
        title={data.title}
        sx={{
          height: 200,
        }}
      />

      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <Typography
          variant="h4"
          color="textPrimary"
          sx={{
            textAlign: 'center',
            paddingBottom: 2,
          }}
        >
          {data.title}
        </Typography>

        <Typography
          variant="body1"
          color="textPrimary"
          sx={{
            textAlign: 'center',
          }}
        >
          {data.content}
        </Typography>
      </Box>
    </Box>
  );
}
