import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';

export default function WhyWorkWithUs() {
  const theme = useTheme();
  // https://mui.com/components/use-media-query/
  // use the media query to check the size of the screen
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  // return the proper justify content parameter based on the screen
  // if larger than sm then use space-between else use center
  const logoWidthByBreakpoint = () => {
    if (isSm) return '150';
    else return '250';
  };

  return (
    <Box sx={{ backgroundColor: '#F1F1F1', paddingTop: 4, paddingBottom: 4 }}>
      {/* To vertically align the items use  */}
      <Grid container columns={2} justifyContent="center" alignItems="center">
        {/* A value given to a breakpoint applies to all the other breakpoints wider than it unless overridden */}
        {/* For example, xs={12} sizes a component to occupy the whole viewport width regardless of its size. */}
        {/* xs={2} means that by default the width of the column is like the whole viewport */}
        {/* If the size of the viewport is more than sm, then the width of the column is 1 of 2 */}
        <Grid item xs={2} md={1}>
          <Box sx={{ paddingRight: isSm ? 2 : 4, backgroundColor: '#CAFFE200' }}>
            <Typography variant="body1" sx={{ paddingBottom: 2 }}>
              י.א.ל. ליווי וקידום עסקים הינה חברת ייעוץ מקצועית בתחום התפעול אשר שמה לעצמה מטרה לקדם את התעשיה היצרנית בתחום שרשרת אספקה.
            </Typography>
            <Typography variant="body1" sx={{ paddingBottom: 2 }}>
              החברה הוקמה על מנת לשפר את היכולת של התעשיה בתחום שרשרת האספקה ובנוסף להכשיר כח אדם מיומן עבור התחום, אשר מעוניין להשתלב בתפקידים בכירים בתחום התעשיה וההיטק.
            </Typography>
            <Typography variant="body1" sx={{}}>
              אנו מעסיקים מגוון מדריכים מקצועיים, מהנדסים בעלי נסיון רב אשר מספקים שירות בתחום של אפיון מערכות לוגיסטיות. המטרה שלנו הינה לקדם את העסק שלכם ולהביא אותו לרווחיות
              מקסימלית.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} md={1}>
          <Box display="flex" justifyContent="center" sx={{ padding: 2, backgroundColor: '#CAFFE200' }}>
            <img alt="ial logo" src="/images/ial-logo-big.svg" width={logoWidthByBreakpoint()} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
