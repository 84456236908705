import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function TitleRow(props) {
  const data = props.data;

  return (
    <Box
      sx={{
        paddingBottom: 3,
      }}
    >
      <Box
        sx={{
          backgroundColor: '#F0C50D',
          // display: 'block',
          width: 60,
          height: 5,
          marginTop: 3,
          marginBottom: 1,
        }}
      />
      <Typography variant="h4" sx={{}}>
        {data.title}
      </Typography>
    </Box>
  );
}
