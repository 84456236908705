import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function ContactDetails() {
  return (
    <Box sx={{ backgroundColor: '#F1F1d100', paddingTop: 4, paddingBottom: 4 }}>
      <Typography variant="h4" align="center">
        יצירת קשר
      </Typography>

      <Box display="flex" justifyContent="center" sx={{ padding: 1, backgroundColor: '#C00FE200' }}>
        <Link href="https://wa.me/972547248486" target="_blank" sx={{}}>
          <Typography variant="body2">וואטסאפ 054-724-8486</Typography>
        </Link>
      </Box>

      <Box display="flex" justifyContent="center" sx={{ padding: 1, backgroundColor: '#CAFFE200' }}>
        <Link href="tel:+972-54-724-8486" target="_blank" sx={{}}>
          <Typography variant="body2">נייד 054-724-8486</Typography>
        </Link>
      </Box>

      <Box display="flex" justifyContent="center" sx={{ padding: 1, backgroundColor: '#C3E2FF00' }}>
        <Link href="mailto:i.a.l.supplychain.consulting2021@gmail.com" target="_blank">
          <Typography variant="body2">i.a.l.supplychain.consulting2021@gmail.com</Typography>
        </Link>
      </Box>
    </Box>
  );
}
