
import { createTheme } from '@mui/material/styles';
import green from '@mui/material/colors/green';

// create a custom theme
// https://material-ui.com/styles/advanced/
// Set the direction in your custom theme:

const archBlue = "#0B72B9";
const archOrange = "#FFBA60";
const greenApp = "#357a38";
const font = "'Rubik', sans-serif;"

let theme = createTheme({
  direction: 'rtl',

  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: font,
      },
    },
  },

  palette: {
    // create some custom colors that can be accessed later via the theme object
    common: {
      // use as template string to inject the javascript variable
      blue: `${archBlue}`,
      greenApp: `${greenApp}`,
      orange: `${archOrange}`,
      companyTitleColor: '#666666',
      mainBannerColor: '#333333',
      siteGrayBackground: 'rgba(247,247,247,255)',
      divider: '#ebebeb',
      cardIconColor: '#1e88e5',
    },
    primary: green,
    secondary: {
      main: `${archOrange}`
    },
  },
});

export default theme;