import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Footer() {
  return (
    <Box sx={{ padding: 2, backgroundColor: '#323232' }}>
      <Typography variant="body2" color="#FFFFFF" align="center">
        י.א.ל. הדרכות וליווי עסקים
      </Typography>

      <Typography variant="body2" color="#FFFFFF" align="center">
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}
