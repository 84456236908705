import React, { useState, useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import * as routes from 'util/routes';
import * as hebMapping from 'util/hebMapping';
import AppBar from '@mui/material/AppBar';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Header() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  // useState is a hook
  // Hooks are a new addition in React 16.8. They let you use state and other React features without writing a class.
  // useState returns a pair: the current state value and a function that lets you update it
  // The only argument to useState is the initial state
  const [activeTabIndex, setActiveTabIndex] = useState(routes.ROOT_INDEX);

  const handleChange = (event, activeTabIndex) => {
    setActiveTabIndex(activeTabIndex);
  };

  // Hooks are a new addition in React 16.8. They let you use state and other React features without writing a class.
  // fix for setting the tab indicator on the correct tab if the page is refreshed on navigating via link
  // specify the code to be executed each time the component is refreshed
  // The Effect Hook, useEffect, adds the ability to perform side effects from a function component
  // It serves the same purpose as componentDidMount, componentDidUpdate, and componentWillUnmount in React classes, but unified into a single API
  useEffect(
    () => {
      // if trying to access the home, and the value of the activeTabIndex has not been set, then set it
      if (window.location.pathname === routes.ROOT && activeTabIndex !== routes.ROOT_INDEX) {
        setActiveTabIndex(routes.ROOT_INDEX);
      } else if (window.location.pathname.includes(routes.ABOUT) && activeTabIndex !== routes.ABOUT_INDEX) {
        setActiveTabIndex(routes.ABOUT_INDEX);
      }
    },
    // passing an array of dependencies that are used in the useEffect function
    // this tells the useEffect to run the code only if the value of one of the objects in the array has changed
    [activeTabIndex]
  );

  return (
    <React.Fragment>
      <AppBar elevation={0} position="static" sx={{ backgroundColor: 'white' }}>
        <Container maxWidth="xl">
          <Grid container alignItems="center" columns={2}>
            <Grid item xs={2} md={1}>
              <Box sx={{ backgroundColor: '#FFE7C300', marginTop: isMd ? 2 : 0, marginBottom: isMd ? 2 : 0 }}>
                <Stack direction="row" alignItems="center" sx={{ justifyContent: isMd ? 'center' : 'flex-start' }}>
                  <Link href="https://www.example.com">
                    <img alt="ial logo" src="/images/ial-logo.svg" height="30" />
                  </Link>
                  <Typography sx={{ fontFamily: 'Rubik', fontSize: isMd ? 22 : 32, color: '#404143', marginRight: 3 }}>הדרכות וליווי עסקים</Typography>
                </Stack>
              </Box>
            </Grid>

            <Grid item xs={2} md={1}>
              <Box sx={{ backgroundColor: '#C3E2FF00' }}>
                <Stack direction="row" alignItems="center" sx={{ justifyContent: isMd ? 'center' : 'flex-end' }}>
                  <Link href="mailto:i.a.l.supplychain.consulting2021@gmail.com" target="_blank" sx={{ marginLeft: 2 }}>
                    <img alt="gmail" src="/images/icons/icon-gmail.svg" width="20" height="20" />
                  </Link>
                  <Link href="https://wa.me/972547248486" target="_blank" sx={{ marginLeft: 2 }}>
                    <img alt="whatsapp" src="/images/icons/icon-whatsapp.svg" width="20" height="20" />
                  </Link>
                  <Link href="https://www.linkedin.com/in/dmitry-shaulov" target="_blank" sx={{ marginLeft: 2 }}>
                    <img alt="whatsapp" src="/images/icons/icon-linkedin.svg" width="20" height="20" />
                  </Link>
                  <Typography sx={{ fontFamily: 'Rubik', fontSize: isMd ? 16 : 20, color: '#404143' }}>054-724-8486</Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>

          <Toolbar sx={{}} variant="dense">
            <Tabs
              value={activeTabIndex}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  display: 'none',
                },
              }}
            >
              <Tab sx={{ fontFamily: 'Rubik', fontSize: 16, color: '#404143' }} label={hebMapping.ROOT} component={RouterLink} to={routes.ROOT} />
              <Tab sx={{ fontFamily: 'Rubik', fontSize: 16, color: '#404143' }} label={hebMapping.ABOUT} component={RouterLink} to={routes.ABOUT} />
            </Tabs>
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
}
