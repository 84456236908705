import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Banner() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/banners/home-top-1366x500-no-text.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        minHeight: '500px',
        [theme.breakpoints.down('sm')]: {
          minHeight: '300px',
          backgroundPosition: 'right',
        },
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          marginRight: 'auto',
          marginTop: 'auto',
          marginLeft: isSm ? 'auto' : 10,
          marginBottom: isSm ? 0 : 10,
        }}
      >
        <img alt="ial logo" src="/images/banner-text.png" />
      </Box>
    </Box>
  );
}
