import React from 'react';
import { Routes, Route } from 'react-router-dom';
import * as routes from 'util/routes';
// use name with lower letter because this is not component
import theme from './Theme';
import Header from 'components/header/Header';
import { ThemeProvider } from '@mui/styles';
import { BrowserRouter } from 'react-router-dom';
import Home from 'modules/home/Home';
import Footer from 'components/footer/Footer';
import About from 'modules/about/About';

class App extends React.Component {
  render() {
    return (
      // the custom theme is provided to the whole app
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path={routes.ROOT} element={<Home />} />
            <Route path={routes.ABOUT} element={<About />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;
