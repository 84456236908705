import React from 'react';
import './home.css';
import Banner from 'components/banners/Banner';
import BannerServices from 'components/banners/BannerServices';
import Container from '@mui/material/Container';
import TitleRow from 'components/cards/TitleRow';
import WhyWorkWithUs from 'components/banners/WhyWorkWithUs';
import ContactDetailsPlain from 'components/banners/ContactDetailsPlain';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container maxWidth="xl">
          <Banner />

          <TitleRow
            data={{
              title: 'שירותים',
            }}
          />

          <BannerServices />

          <TitleRow
            data={{
              title: 'למה לעבוד איתנו',
            }}
          />

          <WhyWorkWithUs />
          <ContactDetailsPlain />
        </Container>
      </React.Fragment>
    );
  }
}

export default Home;
