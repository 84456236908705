import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';

export default function BannerAbout() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundImage: 'url(/images/banners/about-top-1366x500.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        minHeight: '500px',
        [theme.breakpoints.down('sm')]: {
          minHeight: '300px',
          backgroundPosition: 'left',
        },
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    ></Box>
  );
}
