const serviceCardsData = {
  specialization: {
    image: '/images/services/specialization.jpg',
    title: 'התמחות',
    content: 'יועצים בעלי ניסיון מעשי רב בתחומי התעשיה היצרנית וההייטק. הצוות שלנו כולל מהנדסים ובעלי תפקידים בכירים מארגונים שונים',
  },
  consulting: {
    image: '/images/services/consulting.jpg',
    title: 'ליווי אישי ומקצועי',
    content: 'התמקדות ברמות הידע של בעלי התפקיד בשרשרת האספקה בעיקר, ודרישות הפרופיל בארגון תוך שמירה על קידום אישי בהתמקדות דרישות הארגון',
  },
  process: {
    image: '/images/services/process.jpg',
    title: 'אפיון תהליכים',
    content: 'בזכות הידע והיכולת להתאים את הטכנולוגיה לשינויים ודרישות השוק, אנו בחברת י.א.ל. עוזרים לבצע אופטימיזציה של תהליכים בתחומים הרלוונטים ללקוח',
  },
};

export default serviceCardsData;
